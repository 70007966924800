<template>
    <div>
      <el-select
        v-model="selectedValue"
        filterable
        clearable
        :multiple="isMultiple"
        :placeholder="placeholder"
        @change="handleChange"
        v-loading="loading"
      >
        <el-option
          v-for="user in userList"
          :key="user.id"
          :label="user.userName"
          :value="user.id"
        />
      </el-select>
    </div>
  </template>
  
  <script>
  export default {
    name: "UserSelect",
    props: {
      isMultiple: {
        type: Boolean,
        default: false, 
      },
      returnObj: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: "请选择用户",
      },
      queryParams: {
        type: Object,
        default: () => ({}),
      },
      value: {
        type: [String, Number, Array],
        default: "",
      },
    },
    data() {
      return {
        loading: false,
        userList: [],
        selectedValue: this.value,
      };
    },
    watch: {
      value(newVal) {
        this.selectedValue = newVal;
      },
      'queryParams.deptId': {
        immediate: true,
        handler(val) {
          if (val) {
            this.getUserList();
          }
        },
      },
      // queryParams: {
      //   immediate: true,
      //   deep: true,
      //   handler(val) {
      //     if (val.deptId) {
      //       this.getUserList();
      //     }
      //   },
      // },
    },
    methods: {
      getUserList() {
        let deptId = this.queryParams.deptId;
        this.loading = true;
        this.$api.user
          .listStaff({
            pageNow: 1,
            pageSize: 10000,
            deptId,
          })
          .then((res) => {
            if (res.code == 200) {
              this.userList = res.data.records;
            }
          })
          .catch((err) => {
            console.log("err", err);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      handleChange(value) {
          this.$emit("input", value);
          if (this.returnObj) {
              if (Array.isArray(value)) {
                  const selectedUsers = value.map(id => 
                      this.userList.find(user => user.id === id)
                      ).filter(user => user); // 过滤掉未找到的用户
                  this.$emit("changeGetObj", selectedUsers);
              } else {
                  const selectedUser = this.userList.find(user => user.id === value);
                  this.$emit("changeGetObj", selectedUser);
              }
          }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .el-select {
    width: 100%;
  }
  </style>
  